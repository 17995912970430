<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
//import DatePicker from "vue2-datepicker";
//import NumberInputSpinner from "vue-number-input-spinner";
import Swal from "sweetalert2";
import axios from 'axios';
import error403 from '../utility/error-403';

import {
  //required,
  //email,
  //minLength,
  //sameAs,
  //maxLength,
  //minValue,
  //maxValue,
  //numeric,
  //url,
  //alphaNum,
  //requiredIf
} from "vuelidate/lib/validators";

/**
 * Form validation component
 */
export default {
  page: {
    title: "Set workorder labor in progress",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader, error403 /*DatePicker, NumberInputSpinner*/ },
  props:{
    id:[Number,null],
    rights: Array
  },
  data() {
    return {
      title: "Set workorder labor in progress",
      items: [
        {
          text: "Shop",
          href: "/"
        },
        {
          text: "Workorders",
          href: "/Shop/workorders"
        },
        {
          text: "Play",
          active: true
        }
      ],
      form: {
      },

      de_notes:           "",
      de_material:        "",
      num_quantity:       "",


      submitted:          false,
      pendingTasks:       false,
      pendingPORs:        false,
      validToken:         false,
      validAccess:        true,
      wo:                 {},
      sites:              [],
      labors:             [],
      materials:          [],

      materialsFields:     ["id_purchaseorder", "de_material", "de_notes", "num_quantity", "action"],
    };
  },
  validations: {
    form: {
    }
  },
  watch:{
    validToken(newValue){
      if( newValue == false ){
        this.$bvModal.show('loginModal');
      }
    },
    "rights": function(newvalue, oldvalue){
      if(oldvalue !== newvalue){
        if(!newvalue.includes(this.user.user.id_role)){
          this.validAccess = false;
        }else{
          this.validAccess = true;
        }
      }
    }
  },
  created(){
    if(localStorage){
      this.user = JSON.parse( localStorage.getItem('user') );
    }
  },
  mounted() {
    if( this.$route.params.id ){
      this.getWorkorderByID( this.$route.params.id );
      this.getPurchaseOrdersByWorkorderID(this.$route.params.id);
    }
  },
  methods: {
    getWorkorderByID(idWorkorder){
      let app = this;
      axios
      .get(
        window.serverPath+'dist/mvw/controllers/workorders.php',
        {
          params: {
            request: 'getWorkorderByID',
            id: idWorkorder,
            token: this.user.token,
          }
        }
      )
      .then(response => {
        if( response.status == 200 ){
          if( response.data == 'Invalid token' ){
            this.validToken = false;
          }else{
            let data    = response.data;

            app.wo      = data.wo;
            app.labors  = data.ct.de_spareParts;

            app.labors.forEach(function(labor){
              if(labor.status){
                labor.selected = true;
              }else{
                app.pendingTasks = true;
              }
            });
          }
        }else{
          Swal.fire("Ooops!", "It was nor possible to load the workorder! Please load this module again.", "error");
        }
      });
    },

    getPurchaseOrdersByWorkorderID(idWorkorder){
      let app = this;
      axios
      .get(
        window.serverPath+'dist/mvw/controllers/purchaseorders.php',
        {
          params: {
            request: 'getPurchaseOrdersByWorkorderID',
            id: idWorkorder,
            token: this.user.token,
          }
        }
      )
      .then(response => {
        if( response.status == 200 ){
          if( response.data == 'Invalid token' ){
            this.validToken = false;
          }else{
            let data = response.data;
            app.materials = data;

            app.materials.forEach(function(material){
              if( material.de_status != 'COMPLETED' ){
                app.pendingPORs = true;
              }
            });
          }
        }else{
          Swal.fire("Ooops!", "It was nor possible to load the workorder! Please load this module again.", "error");
        }
      });
    },

    savePORs(){
      let app = this;

      if( this.de_material != ""  &&  this.num_quantity != "" ){
        if( !isNaN(this.num_quantity) ){
          this.materials.push({
            de_notes:           this.de_notes,
            de_material:        this.de_material,
            num_quantity:       this.num_quantity,
            id_workorder:       this.$route.params.id,
            id_createdByUserID: 12,
            de_status: "NEW"
          });

          this.de_notes         = "";
          this.de_material      = "";
          this.num_quantity     = "";

          Swal.fire({
            title: "Good!",
            text: "Would you like to add another item?",
            icon: "success",
            confirmButtonText: "Yes, I want it!",
            cancelButtonText: "No, I don't!",
            showCancelButton: true
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              Swal.close();
            }else{
              Swal.close();
              app.$bvModal.hide('new-purchaseorder');
            }
          });
        }
      }else{
        Swal.fire("Ooops!","Please complete Material and/or Quantity fields.","error")
      }
    },

    // eslint-disable-next-line no-unused-vars
    saveWorkorder() {
      let app     = this;
      let counter = 0;

      this.labors.forEach(function(labor){
        if(labor.selected == true){
          counter ++;

          labor.status = 'In Progress';
          delete labor.selected;
        }else{
          try{ delete labor.selected}
          catch(e){
            console.log(e);
          }
        }
      });

      if(counter > 0){
        Swal.fire({
          title: "Placing in progress labor(s).",
          showConfirmButton:false,
          onBeforeOpen: () => {
            Swal.showLoading();
          }
        });

        axios.post(window.serverPath+'dist/mvw/controllers/workorders.php', {
          request: 'setWorkorderInProgress',
          data: {
            pors:   this.materials,
            labor:  this.labors,
            woid:   this.$route.params.id,
          },
          token:    this.user.token,
        })
        .then(response => {
          if( response.status == 200 ){
            if( response.data == 'Invalid token' ){
              this.validToken = false;
            }else{
              let action = "";
              if( response.data === "OK" ){
                action = "saved";
              }
              if( response.data === "UPDATED" ){
                action = "updated";
              }

              Swal.fire("Good job!", "Your labor is correct "+action+"!", "success")
              .then((result) => {
                if (result.isConfirmed) {
                  Swal.close();
                  app.$router.push({ name: 'workorders' });
                }
              });
            }
          }else{
            Swal.fire("Ooops!", "It was nor possible to load the workorder! Please load this module again.", "error");
          }
        })
        .catch(function (error) {
          Swal.fire("Ooops!" + error.message, "Seems like some error ocurr, please try again.", "error");
        });
      }else{
        Swal.fire("Ooops!", "Please select at least 1 labor to execute for this workorder.", "error");
      }
    },

    closeWorkorder(){
      let app     = this;

      Swal.fire({
        title: "Closing workorder # "+this.$route.params.id+".",
        showConfirmButton:false,
        onBeforeOpen: () => {
          Swal.showLoading();
        }
      });

      axios.post(window.serverPath+'dist/mvw/controllers/workorders.php', {
        request: 'closeWorkorder',
        data: {
          labor:  this.labors,
          woid:   this.$route.params.id,
        },
        token: this.user.token,
      })
      .then(response => {
        if( response.status == 200 ){
          if( response.data == 'Invalid token' ){
            this.validToken = false;
          }
          else{
            let action = "";

            if( response.data === "OK" ){
              action = "completed";

              Swal.fire("Good job!", "Workorder successfull "+action+"!", "success")
              .then((result) => {
                if (result.isConfirmed) {
                  Swal.close();
                  app.$router.push({ name: 'workorders' });
                }
              });
            }

            if( response.data.error ){
              Swal.fire("Ooops!", "There was an error when trying to close this workorder! Please try again.", "error");
            }
          }
        }else{
          Swal.fire("Ooops!", "There was an error when trying to close this workorder! Please try again.", "error");
        }
      })
      .catch(function (error) {
        Swal.fire("Ooops!" + error.message, "Seems like some error ocurr, please try again.", "error");
      });
    },

    
    closeWorkorderLabor(labor){
      let app       = this;
      labor.status  = "COMPLETED";

      axios.post(window.serverPath+'dist/mvw/controllers/workorders.php', {
        request: 'closeWorkorderLabor',
        data: {
          woid:   this.$route.params.id,
          labor:  labor,
        },
        token: this.user.token,
      })
      .then(response => {
        if( response.status == 200 ){
          if( response.data == 'Invalid token' ){
            this.validToken = false;
          }
          else{
            if( !isNaN(response.data) ){

              app.$bvToast.toast(
                "Labor #"+labor.id_labor+" has been received and completed.",
                {
                  title: 'Labor updated',
                  variant: 'success',
                  solid: true
                }
              );
            }else{
              app.$bvToast.toast(
                "There was an error trying to complete this purchase order. Please try again.",
                {
                  title: 'Labor update error',
                  variant: 'danger',
                  solid: true
                }
              );
            }
          }
        }else{
          Swal.fire("Ooops!", "It was nor possible to load the workorder! Please load this module again.", "error");
        }
      })
      .catch(function (error) {
        Swal.fire("Ooops!" + error.message, "Seems like some error ocurr, please try again.", "error");
      });
    },

    completePOR(por){
      let app     = this;
      let element = this.$refs[por.item.id_purchaseorder];

      element.style.display = 'block';

      axios.post(window.serverPath+'dist/mvw/controllers/purchaseorders.php', {
        request: 'completePurchaseOrder',
        data: {
          id_purchaseorder:   por.item.id_purchaseorder,
          de_status:          'COMPLETED',
        },
        token: this.user.token,
      })
      .then(response => {
        if( response.status == 200 ){
          if( response.data == 'Invalid token' ){
            this.validToken = false;
          }
          else{
            if( !isNaN(response.data) ){
              por.item.de_status = 'COMPLETED';

              app.$bvToast.toast(
                "Purchase order #"+por.item.id_purchaseorder+" has been received and completed.",
                {
                  title: 'POR updated',
                  variant: 'success',
                  solid: true
                }
              );

              let counter = 0;
              app.materials.forEach(function(material){
                if( material.de_status == 'COMPLETED'){
                  counter ++;
                }
              });

              if( counter == app.materials.length){
                app.pendingPORs = false;
              }
            }else{
              app.$bvToast.toast(
                "There was an error trying to complete this purchase order. Please try again.",
                {
                  title: 'POR update error',
                  variant: 'danger',
                  solid: true
                }
              );
            }
            element.style.display = 'none';
          }
        }else{
          Swal.fire("Ooops!", "It was nor possible to load the workorder! Please load this module again.", "error");
          element.style.display = 'none';
        }
      })
      .catch(function (error) {
        Swal.fire("Ooops!" + error.message, "Seems like some error ocurr, please try again.", "error");
        element.style.display = 'none';
      });
    },
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />


    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div v-if="validAccess" class="card-body">
            <form class="needs-validation">
              <!--
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="de_labor">Labor name</label>
                    <input
                      type="text"
                      class="form-control"
                      :class="{ 'is-invalid': submitted && $v.form.de_labor.$error }"
                      name="de_labor"
                      id="de_labor"
                      v-model="form.de_labor"
                      value=""
                    >
                    <div v-if="submitted && $v.form.de_labor.$error" class="invalid-feedback">
                      <span v-if="!$v.form.de_labor.required">Labor name is required.</span>
                      <span v-if="!$v.form.de_labor.minLength">This value length is invalid. It should be between 2 and 200 characters long.</span>
                      <span v-if="!$v.form.de_labor.maxLength">This value length is invalid. It should be between 2 and 200 characters long.</span>
                    </div>
                  </div>

                  <div class="form-group">
                    <label for="num_laborPrice">Labor price</label>
                    <input
                      type="text"
                      class="form-control"
                      :class="{ 'is-invalid': submitted && $v.form.num_laborPrice.$error }"
                      name="num_laborPrice"
                      id="num_laborPrice"
                      v-model="form.num_laborPrice"
                      value=""
                    />
                    <div v-if="submitted && $v.form.num_laborPrice.$error" class="invalid-feedback">
                      <span v-if="!$v.form.num_laborPrice.required">Labor price is required.</span>
                      <span v-if="!$v.form.num_laborPrice.minValue">This minimum price is invalid. Price should be greather than $0.00 .</span>
                    </div>
                  </div>

                  <div class="form-group">
                    <label for="id_site">Site</label>
                    <select
                      class="form-control"
                      :class="{ 'is-invalid': submitted && $v.form.id_site.$error }"
                      name="id_site"
                      id="id_site"
                      v-model="form.id_site"
                    >
                      <option value="">SELECT YOUR SITE</option>
                      <option v-for="(site, index) in sites" :key="index" :value="site.id_site">{{site.nm_site}}</option>
                    </select>
                    <div v-if="submitted && $v.form.id_site.$error" class="invalid-feedback">
                      <span v-if="!$v.form.id_site.required">Site field is required.</span>
                    </div>
                  </div>
                </div>
              </div>
              -->

              <div class="row">
                <div class="col-sm-6">
                  <h5 class="card-title">Workorder Labors</h5>
                  <div class="row">
                    <div class="col-sm-4" v-for="(labor, index) in labors" :key="index">
                      <div class="form-group-sm">
                        <b-form-checkbox v-model="labors[index].selected" switch class="mb-1" :disabled="labor.status=='COMPLETED'">
                          <label>
                            {{labor.labor}}
                            <span v-if="!labor.status || labor.status==''" class="badge btn-light">Not started</span>
                            <span v-if="labor.status=='IN PROGRESS'" class="badge badge-info">{{labor.status}}</span>
                            <span v-if="labor.status=='COMPLETED'" class="badge badge-success">{{labor.status}}</span>
                          </label>
                          <button v-if="labor.status != 'COMPLETED'" type="button" class="btn btn-sm btn-dark" @click="closeWorkorderLabor(labor)"><i class="fas fa-check-double"></i></button>
                        </b-form-checkbox>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-sm-6">
                  <div class="row mb-3">
                      <div class="col-sm-6"><h5 class="card-title">Workorder Purchase order request(s)</h5></div>
                      <div class="col-sm-6 text-right"><button v-b-modal.new-purchaseorder type="button" class="btn btn-success btn-sm"><i class="fas fas fa-plus"></i> Add</button></div>
                  </div>
                  
                  <b-table
                    sticky-header
                    small
                    :items="materials"
                    :fields="materialsFields"
                    responsive="sm"
                    style="max-height: 450px !important; font-size: 12px;"
                  >
                    <!-- Example scoped slot for select state illustrative purposes -->
                    <template #cell(action)="data">
                        <button v-if="!data.item.id_purchaseorder" type="button" class="btn btn-danger btn-sm" @click="materials.splice(data.index,1)" title="Remove this material."><i class="fas fa-trash-alt"></i></button>
                        <button v-if="data.item.id_purchaseorder && data.item.de_status=='IN PROGRESS'" type="button" class="btn btn-dark btn-sm" @click="completePOR(data)" title="Receive this material."><i class="fas fa-hand-holding"></i></button>
                        <span v-if="data.item.de_status=='ON HOLD'" class="badge badge-warning p-1">{{data.item.de_status}}</span>
                        <span v-if="data.item.de_status=='COMPLETED'" class="badge badge-success p-1">{{data.item.de_status}}</span>
                        <b-spinner small variant="dark" role="status" :id="data.item.id_purchaseorder" :ref="data.item.id_purchaseorder" style="display: none;"></b-spinner>
                    </template>
                  </b-table>

                  <b-modal no-close-on-backdrop
                    id="new-purchaseorder"
                    title="Purcharse order request"
                    title-class="font-18"
                    size="lg"
                    hide-footer
                  >
                    <div class="row">
                      <div class="col-sm-12">
                        <div class="form-group">
                          <label for="de_material">Material</label>
                          <input
                            type="text"
                            class="form-control"
                            name="de_material"
                            id="de_material"
                            v-model="de_material"
                          />
                        </div>
                        <div class="form-group">
                          <label for="num_quantity">Quantity</label>
                          <input
                            type="text"
                            class="form-control"
                            name="num_quantity"
                            id="num_quantity"
                            v-model="num_quantity"
                          />
                        </div>
                        <div class="form-group">
                          <label for="de_notes">Notes (justification, comments)</label>
                          <textarea name="textarea" v-model="de_notes" rows="5" class="form-control"></textarea>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-lg-12 mt-5 pt-5">
                        <div class="form-group text-center">
                          <button class="btn btn-success" type="button" @click="savePORs">SAVE POR(s)</button>
                          &nbsp;&nbsp;
                          <button class="btn btn-danger" type="reset" @click="$bvModal.hide('new-purchaseorder')">CANCEL </button>
                        </div>
                      </div>
                    </div>
                    <p>I will not close if you click outside me. Don't even try to press escape key.</p>
                  </b-modal>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-12 mt-5 pt-5">
                  <div class="form-group text-center">
                    <button v-if="pendingTasks || pendingPORs" class="btn btn-success" type="button" @click="saveWorkorder">SET IN PROGRESS</button>
                    <button v-else-if="wo.de_status!='COMPLETED'" class="btn btn-success" type="button" @click="closeWorkorder">CLOSE WORKORDER</button>

                    <router-link to="/shop/workorders" class="ml-3">
                      <button v-if="wo.de_status!='COMPLETED'" class="btn btn-danger" type="reset">CANCEL </button>
                      <button v-else class="btn btn-danger" type="reset">BACK TO LOG </button>
                    </router-link>
                  </div>
                </div>
              </div>
            </form>
          </div>

          <div v-else class="card-body">
            <div class="row">
              <div class="col-sm-12 text-center">
                <error403></error403>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </Layout>
</template>
<style type="text/css">
  .is-invalid  .mx-input{
    border: solid 1px red !important;
  }
</style>